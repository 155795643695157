import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'reactstrap'

class CheckBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
    }
  }

  toggleCheck = () => {
    const isChecked = !this.state.checked
    this.props.onCheck(this.props.id, isChecked)
    this.setState({ checked: isChecked })
  }

  render() {
    const { label, sublabel, icon } = this.props
    return (
      <Row
        className={`checkbox-bg ${this.state.checked &&
          'checkbox-bg-selected'}`}
        onClick={() => this.toggleCheck()}
      >
        <Col xs={1} className="md-checkbox">
          <input type="checkbox" checked={this.state.checked} readOnly />
          <label />
        </Col>
        <Col xs={2} className="checkbox-icon-wrapper">
          <FontAwesomeIcon icon={icon} className={`checkbox-icon ${this.state.checked && 'checkbox-icon-active'}`} />
        </Col>
        <Col className="checkbox-label-container">
          <div className="checkbox-labels-wrapper">
            <p className="checkbox-label">{label}</p>
            <p className="checkbox-sublabel">{sublabel}</p>
          </div>
        </Col>
      </Row>
    )
  }
}

CheckBox.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.object,
  onCheck: PropTypes.func,
  sublabel: PropTypes.string,
}

export default CheckBox
