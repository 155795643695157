import React, { Component } from 'react'
import CEO from './CEO'
import { StepZero } from './StepZero'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive, { ButtonState } from './StepFive'
import axios from 'axios'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

class EstimationForm extends Component {
  RE_CAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY_V3

  constructor(props) {
    super(props)
    this.state = {
      estimationButtonState: ButtonState.STATE_READY,
      reCaptchaResponseToken: null,
      reloadReCaptcha: true,
      attachmentFormatError: '',
    }
  }

  estimationModel = {
    stepOneSelectedOption: '',
    stepOneDescription: '',
    stepOneAttachmentFiles: '',
    stepTwoSelectedRequirements: new Set(),
    stepThreeSelectedIntegrations: new Set(),
    stepThreeOtherFieldValue: '',
    stepFourSelectedOption: '',
    stepFiveName: '',
    stepFiveEmail: '',
    stepFivePhone: '',
    stepFiveNda: false,
  }

  updateEstimationModel = newState => {
    this.estimationModel = { ...this.estimationModel, ...newState }
  }

  componentDidMount() {
    loadReCaptcha(process.env.GATSBY_RECAPTCHA_SITE_KEY_V3);
  }

  sendEstimationModel = () => {
    if (this.state.estimationButtonState === ButtonState.STATE_SENDING) return;

    this.setState({ estimationButtonState: ButtonState.STATE_SENDING });
    window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_SITE_KEY_V3).then(token => {

      this.setState({ attachmentFormatError: '' });
      const url = `${process.env.GATSBY_MAILING_URL}/api/estimation`;
      const remoteModel = {
        projectType: this.estimationModel.stepOneSelectedOption,
        description: this.estimationModel.stepOneDescription,
        requires: Array.from(this.estimationModel.stepTwoSelectedRequirements),
        integrations: Array.from(
          this.estimationModel.stepThreeSelectedIntegrations
        ),
        otherIntegrations: this.estimationModel.stepThreeOtherFieldValue,
        budget: this.estimationModel.stepFourSelectedOption,
        contactDetails: {
          name: this.estimationModel.stepFiveName,
          email: this.estimationModel.stepFiveEmail,
          phoneNumber: this.estimationModel.stepFivePhone,
          ndaDraft: this.estimationModel.stepFiveNda,
        },
        'g-recaptcha-response': token,
        attachments: [],
      };

      // get urls for upload
      const estimationPromises = [];
      // eslint-disable-next-line
      this.estimationModel.stepOneAttachmentFiles.map(file => {
        estimationPromises.push(
          axios.get(
            `${
              process.env.GATSBY_MAILING_URL
              }/api/getEstimationAttachmentUrl?format=${file.format}`
          )
        )
        // eslint-disable-next-line
      });

      // prepare array of promises for azure upload
      const attachmentObjets = [];
      const azureBlobUrls = [];

      axios
        .all(estimationPromises)
        .then(res => {
          // eslint-disable-next-line
          res.map((response, index) => {
            attachmentObjets.push({
              name: this.estimationModel.stepOneAttachmentFiles[index].name,
              url: response.data.data.uploadUrl
            });
            azureBlobUrls.push(
              axios.put(
                response.data.data.uploadUrl,
                this.estimationModel.stepOneAttachmentFiles[index].blob,
                {headers: {'x-ms-blob-type': 'blockBlob'}}
              )
            )
          });

          // add array of urls to model
          remoteModel.attachments = attachmentObjets;
          // send all request to azure
          axios
            .all(azureBlobUrls)

            // send final model to backend API
            .then(() => {
              axios
                .post(url, remoteModel)
                .then(() => {
                  this.setState({estimationButtonState: ButtonState.STATE_SENT})
                })
                .catch(() => {
                  this.setState({
                    estimationButtonState: ButtonState.STATE_ERROR,
                  })
                })
            })
            .catch(() => {
              this.setState({estimationButtonState: ButtonState.STATE_ERROR})
            })
        })
        .catch((e) => {
          // eslint-disable-next-line
          e.response.data.meta.errors.map(error => {
            if (error.code === "disallowed_file_format") {
              this.setState({ attachmentFormatError:  error.reason })
            }
          });
          this.setState({ estimationButtonState: ButtonState.STATE_ERROR })
        })
        .finally(() => {
          this.setState({reCaptchaResponseToken: null, reloadReCaptcha: true})
        })
    });
  };

  renderRecaptcha = () => {
    return (
      <ReCaptcha
        sitekey={this.RE_CAPTCHA_SITE_KEY}
        verifyCallback={this.verifyCallback}
      />
    )
  }

  verifyCallback = recaptchaToken => {
    this.setState({ reCaptchaResponseToken: recaptchaToken })
  }

  render() {
    return (
      <div>
        <CEO />
        <StepZero />
        <StepOne attachmentFormatError={this.state.attachmentFormatError} onUpdate={newState => this.updateEstimationModel(newState)} />
        <StepTwo onUpdate={newState => this.updateEstimationModel(newState)} />
        <StepThree
          onUpdate={newState => this.updateEstimationModel(newState)}
        />
        <StepFour onUpdate={newState => this.updateEstimationModel(newState)} />
        <StepFive
          onUpdate={newState => this.updateEstimationModel(newState)}
          estimationButtonState={this.state.estimationButtonState}
          onSubmit={this.sendEstimationModel}
        />
        {this.renderRecaptcha()}
      </div>
    )
  }
}

export default EstimationForm
