import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Container, Row } from 'reactstrap'
import CheckBox from './CheckBox'
import Divider from '../common/Divider'
import {
  faBrowser,
  faDatabase,
  faMobileAndroid,
  faObjectUngroup,
} from '@fortawesome/pro-light-svg-icons'

class StepTwo extends Component {
  options = {
    MOBILE_APPS: 'Mobile Apps',
    WEB_APPS: 'Web Apps',
    BACKEND: 'Backend',
    DESIGN: 'Design',
  }

  optionsSet = new Set()

  handleCheckBoxCheck = (id, isChecked) => {
    isChecked ? this.optionsSet.add(id) : this.optionsSet.delete(id)
    this.props.onUpdate({ stepTwoSelectedRequirements: this.optionsSet })
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col md={1} lg={2}>
              <h1 className="step-label">2</h1>
            </Col>
            <Col md={10} lg={8}>
              <Row>
                <Col xs={12}>
                  <h2 className="m-0 py-3">My project requires...</h2>
                </Col>
                <Col sm={12} md={6} className="pr-3 pt-3">
                  <CheckBox
                    id={this.options.MOBILE_APPS}
                    label="Mobile apps"
                    icon={faMobileAndroid}
                    sublabel="for iPhone/iPad, Android devices"
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pl-3 pt-3">
                  <CheckBox
                    id={this.options.WEB_APPS}
                    label="Web apps"
                    icon={faBrowser}
                    sublabel="apps that run in the browser"
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pr-3 pt-3">
                  <CheckBox
                    id={this.options.BACKEND}
                    label="Server-side solutions"
                    icon={faDatabase}
                    sublabel="back-end services for apps, databases"
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pl-3 pt-3">
                  <CheckBox
                    id={this.options.DESIGN}
                    label="Design"
                    icon={faObjectUngroup}
                    sublabel="UI/UX, product features, architecture"
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col xs={12} className="px-0 py-5">
                  <Divider />
                </Col>
              </Row>
            </Col>
            <Col md={1} lg={2} />
          </Row>
        </Container>
      </div>
    )
  }
}

StepTwo.propTypes = {
  onUpdate: PropTypes.func,
}

export default StepTwo
