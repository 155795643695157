import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import RadioButton from './RadioButton'
import * as PropTypes from 'prop-types'

class StepFour extends Component {
  options = {
    MORE_THAN: 'Over 30K',
    LESS_THAN: 'Less than 30K',
  }

  constructor(props) {
    super(props)
    this.state = {
      stepFourSelectedOption: this.options.LESS_THAN,
    }
    this.props.onUpdate(this.state)
  }

  componentDidUpdate = () => {
    this.props.onUpdate(this.state)
  }

  handleRadioButtonClick = id => {
    this.setState({ stepFourSelectedOption: id })
  }

  render() {
    return (
      <div className="pb-4">
        <Container>
          <Row className="pb-4">
            <Col xs={12} md={1} lg={2}>
              <h1 className="step-label">4</h1>
            </Col>
            <Col xs={12} md={10} lg={8}>
              <Row>
                <Col xs={12} className="mb-4">
                  <h2 className="m-0 py-2">My budget is...</h2>
                </Col>
                <Col sm={12} md={6} className="pr-3 pb-3">
                  <RadioButton
                    label="Less than €30,000"
                    id={this.options.LESS_THAN}
                    onClick={this.handleRadioButtonClick}
                    selected={
                      this.state.stepFourSelectedOption ===
                      this.options.LESS_THAN
                    }
                  />
                </Col>
                <Col sm={12} md={6} className="pl-3 pb-3">
                  <RadioButton
                    label="Over €30,000"
                    id={this.options.MORE_THAN}
                    onClick={this.handleRadioButtonClick}
                    selected={
                      this.state.stepFourSelectedOption ===
                      this.options.MORE_THAN
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={1} lg={2} />
          </Row>
        </Container>
      </div>
    )
  }
}

StepFour.propTypes = {
  onUpdate: PropTypes.func,
}

export default StepFour
