import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'

class NdaCheckBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
    }
  }

  handleClick = () => {
    const isChecked = !this.state.checked
    this.props.onCheckChange(isChecked)
    this.setState({ checked: isChecked })
  }

  render() {
    const { label } = this.props
    return (
      <Row className="checkbox-bg-nda" onClick={() => this.handleClick()}>
        <Col xs={1} className="md-checkbox">
          <input
            id="checkbox-nda"
            type="checkbox"
            checked={this.state.checked}
            readOnly
          />
          <label />
        </Col>
        <Col className="checkbox-label-container p-0">
          <div className="checkbox-labels-wrapper">
            <p className="checkbox-label-nda">{label}</p>
          </div>
        </Col>
      </Row>
    )
  }
}


NdaCheckBox.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  onCheckChange: PropTypes.func,
}

export default NdaCheckBox
