import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'

class RadioButton extends Component {
  render() {
    const { selected, label, id, onClick } = this.props
    return (
      <Row
        className={`radio-button-bg ${selected && 'radio-button-bg-selected'}`}
        onClick={() => onClick(id)}
      >
        <Col xs={1} className="md-radio">
          <input type="radio" checked={selected} readOnly />
          <label />
        </Col>
        <Col className="radio-button-label-wrapper">
          <p className="radio-button-label">{label}</p>
        </Col>
      </Row>
    )
  }
}

RadioButton.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

export default RadioButton
