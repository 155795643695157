import React from 'react'
import NavBar from '../components/common/NavBar'
import EstimationForm from '../components/project-estimate/EstimationForm'
import Helmet from 'react-helmet'

const ProjectEstimatePage = () => (
  <>
    <Helmet
      title={'Mobile app cost estimation. Android & iOS application | Zaven Poland'}
      meta={[
        {
          name: 'description',
          content:
            'Zaven is a globally-awarded enterprise that develops high quality apps. Get a cost estimate for your application. Server side and Firebase solutions.',
        },
      ]}
    />
    <NavBar lightTheme />
    <EstimationForm />
  </>
)

export default ProjectEstimatePage
