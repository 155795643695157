import React, { Component } from 'react'
import { Col, Container, FormGroup, Input, Row } from 'reactstrap'
import CheckBox from './CheckBox'
import Divider from '../common/Divider'
import {
  faBell,
  faCreditCard,
  faEnvelope,
  faHdd,
  faMapMarked,
  faQuestionCircle,
  faShareAlt,
} from '@fortawesome/pro-light-svg-icons'
import * as PropTypes from 'prop-types'

class StepThree extends Component {
  integrations = {
    SOCIAL: 'Social Networks',
    NOTIFICATIONS: 'Push Notifications',
    PAYMENTS: 'Online Payments',
    MESSAGING: 'Messaging',
    STORAGE: 'Storage',
    OTHER: 'Other',
    MAPS: 'Maps',
  }

  constructor(props) {
    super(props)
    this.state = {
      otherFieldVisible: false,
      otherFieldValue: '',
    }
  }

  integrationsSet = new Set()

  componentDidUpdate = () => {
    this.props.onUpdate({
      stepThreeSelectedIntegrations: this.integrationsSet,
      stepThreeOtherFieldValue: this.state.otherFieldValue,
    })
  }

  handleCheckBoxCheck = (id, isChecked) => {
    if (id === this.integrations.OTHER) {
      this.setState({ otherFieldVisible: !this.state.otherFieldVisible })
    }
    isChecked ? this.integrationsSet.add(id) : this.integrationsSet.delete(id)
    this.props.onUpdate({
      stepThreeSelectedIntegrations: this.integrationsSet,
      stepThreeOtherFieldValue: this.state.otherFieldValue,
    })
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} md={1} lg={2}>
              <h1 className="step-label">3</h1>
            </Col>
            <Col xs={12} lg={8} md={10}>
              <Row>
                <Col xs={12}>
                  <h2 className="mb-2 py-2">It will integrate with...</h2>
                </Col>
                <Col sm={12} md={6} className="pr-3 pt-3">
                  <CheckBox
                    id={this.integrations.SOCIAL}
                    label="Social networks"
                    sublabel="e.g. Facebook, Twitter, LinkedIn"
                    icon={faShareAlt}
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pl-3 pt-3">
                  <CheckBox
                    id={this.integrations.PAYMENTS}
                    label="Online payments"
                    sublabel="e.g. Stripe, PayPal, PayU, Apple Pay"
                    icon={faCreditCard}
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pr-3 pt-3">
                  <CheckBox
                    id={this.integrations.MAPS}
                    label="Maps"
                    sublabel="e.g. Google Maps, OpenStreetMap"
                    icon={faMapMarked}
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pl-3 pt-3">
                  <CheckBox
                    id={this.integrations.MESSAGING}
                    label="Messaging"
                    sublabel="e.g. Email, SMS, SendGrid, Twilio, Slack"
                    icon={faEnvelope}
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pr-3 pt-3">
                  <CheckBox
                    id={this.integrations.STORAGE}
                    label="Storage"
                    sublabel="e.g. Google Drive, Dropbox"
                    icon={faHdd}
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pl-3 pt-3">
                  <CheckBox
                    id={this.integrations.NOTIFICATIONS}
                    label="Push notifications"
                    sublabel="e.g. Firebase, Notification Hubs"
                    icon={faBell}
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col sm={12} md={6} className="pr-3 pt-3">
                  <CheckBox
                    id={this.integrations.OTHER}
                    label="Other"
                    icon={faQuestionCircle}
                    sublabel=""
                    onCheck={this.handleCheckBoxCheck}
                  />
                </Col>
                <Col
                  xs={12}
                  className={`${
                    this.state.otherFieldVisible ? 'd-block' : 'd-none'
                  } mt-3`}
                >
                  <FormGroup>
                    <Input
                      placeholder="Please specify"
                      className="form-control contact-details-input"
                      onChange={e =>
                        this.setState({ otherFieldValue: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} className="px-0 py-5">
                  <Divider />
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={1} lg={2} />
          </Row>
        </Container>
      </div>
    )
  }
}

StepThree.propTypes = {
  onUpdate: PropTypes.func,
}

export default StepThree
